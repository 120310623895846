@import '../../../../../styles/variables';

.root {
  display: flex;
  justify-content: center;
  background-color: #FFF8F3;
  overflow: hidden;

  @media screen and (max-width: $xl) {
    padding: 0 80px 104px;
  }

  @media screen and (max-width: $m) {
    padding: 0 20px 80px;
  }

  .container {
    width: 1544px;
    padding: 0 80px;
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;

    @media screen and (max-width: $xl) {
      width: 100%;
      padding: 0;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
    }

    @media screen and (max-width: $m) {
      align-items: center;
    }

    .content {
      display: flex;
      flex-direction: column;
      z-index: 2;

      @media screen and (max-width: $m) {
        width: 100%;
      }

      .title {
        max-width: 450px;
        margin: 168px 0 32px;
        font-family: $fontSansSerifBldFLF;
        font-style: normal;
        font-weight: 550;
        font-size: 72px;
        line-height: 66px;
        text-transform: uppercase;
        color: #181818;

        @media screen and (max-width: $xl) {
          max-width: 380px;
          margin: 104px 0 16px 50px;
          font-size: 56px;
          line-height: 52px;
        }

        @media screen and (max-width: $m) {
          max-width: 100%;
          margin: 80px 0 16px;
          font-size: 32px;
          line-height: 29px;
          letter-spacing: -0.02em;
        }
      }

      .image {
        width: 613px;
        padding-bottom: 32px;

        @media screen and (max-width: $xl) {
          display: none;
        }

        img {
          width: 100%;
        }
      }
    }

    .details {
      display: flex;
      flex-direction: column;
      align-self: center;
      z-index: 2;

      @media screen and (max-width: $m) {
        width: 100%;
        align-items: center;
      }

      .detailsItem {
        width: 470px;
        padding: 26px 16px 10px;
        display: flex;
        align-items: center;
        border-bottom: 1px solid #676767;

        @media screen and (max-width: $xl) {
          width: 502px;
        }

        @media screen and (max-width: $m) {
          width: 320px;
          padding: 26px 0 10px;
        }

        .icon {
          width: 38px;

          svg {
            width: 100%;
            height: 100%;
          }
        }

        .label {
          width: 250px;
          padding-left: 16px;
          font-family: $fontOpenSans;
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          line-height: 22px;
          text-transform: uppercase;
          color: #181818;
          word-wrap: break-word;

          @media screen and (max-width: $m) {
            width: 200px;
            padding: 0 8px;
            font-size: 14px;
            line-height: 19px;
          }
        }

        .value {
          width: 150px;
          font-family: $fontOpenSans;
          font-style: normal;
          font-weight: 500;
          font-size: 16px;
          line-height: 22px;
          color: #474747;
          word-wrap: break-word;

          @media screen and (max-width: $m) {
            width: 82px;
            font-size: 14px;
            line-height: 19px;
          }
        }
      }
    }

    .backgroundText {
      width: 1800px;
      position: absolute;
      left: -80px;
      top: 50px;
      font-family: $fontSansSerifBldFLF;
      font-style: normal;
      font-weight: 550;
      font-size: 300px;
      line-height: 276px;
      text-transform: uppercase;
      color: rgba(24, 24, 24, 0.03);
      z-index: 1;
      pointer-events: none;

      @media screen and (max-width: $xl) {
        width: 1100px;
        left: -60px;
        top: 40px;
        font-size: 191px;
        line-height: 176px;
      }

      @media screen and (max-width: $m) {
        width: 700px;
        font-size: 120px;
        line-height: 110px;
        left: -15px;
        top: 15px;
      }
    }
  }
}

.webApplicationLink {
  font-size: 16px;
  font-weight: 500;
  line-height: 22px;
  text-align: left;
  color: #219653;
  text-decoration: underline;
}