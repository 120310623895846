@import '../../../../../styles/variables';

.root {
  position: fixed;
  top: 50%;
  left: calc(50% - 772px);
  transform: translate(-50%, -50%);
  z-index: 5;

  @media screen and (max-width: 1704px) {
    left: 40px;
  }

  @media screen and (max-width: $xl) {
    left: 65px;
  }

  @media screen and (max-width: $m) {
    display: none;
  }

  &::after {
    content: '';
    position: absolute;
    bottom: 244px;
    left: 50%;
    width: 1px;
    height: 100vh;
    transform: translateX(-50%);
    background-color: #C6C6C6;
  }

  &::before {
    content: '';
    position: absolute;
    top: 244px;
    left: 50%;
    width: 1px;
    height: 100vh;
    transform: translateX(-50%);
    background-color: #C6C6C6;
  }
}

.container {
  display: flex;
  flex-direction: column;
}
