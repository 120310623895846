@import './src/styles/variables';

@mixin link() {
  &.disabled {
    color: #474747;
    pointer-events: none;
  }

  &.active {
    color: #FAB001;
  }

  @media (hover: hover) {
    &:hover {
      color: #FAB001;
    }
  }

  &:active {
    color: #FAB001;
  }
}

// Check $headerHeight in variables.scss
.root {
  width: 100%;
  padding: 0 128px;
  background-color: #0D0D0D;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9;

  @media screen and (max-width: $xl) {
    height: 94px;
    padding: 24px;
    overflow: hidden;
    &.rootOpened {
      height: 100%;
      overflow-y: auto;
    }
  }

  .desktopContainer {
    display: block;

    @media screen and (max-width: $xl) {
      display: none;
    }

    .container {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .links {
        display: flex;
        align-items: center;

        .logo {
          margin-right: 80px;
        }

        .linkContainer {
          position: relative;
          margin-right: 80px;
          padding: 36px 0;

          .link {
            display: flex;
            justify-content: center;
            align-items: center;
            font-family: $fontOpenSans;
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 22px;
            color: #FFFFFF;
            text-transform: uppercase;
            transition: .3s;
            cursor: pointer;

            &:last-child {
              margin-right: 0;
            }

            @include link()
          }

        }
      }

      .loginButton {
        padding: 15px 62px;
        position: relative;
        font-family: $fontOpenSans;
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 22px;
        text-transform: uppercase;
        color: #FFFFFF;
        border: 1px solid #FFFFFF;
        border-radius: 1px;
        transition: .3s;

        @media (hover: hover) {
          &:hover {
            background: rgba(250, 176, 1, 0.03);
            border: 1px solid #FAB001;
          }
        }

        &:active {
          background: rgba(250, 176, 1, 0.33);
          border: 1px solid #FAB001;
        }

        &::after {
          content: '';
          position: absolute;
          top: -1px;
          left: 15px;
          width: 46px;
          height: 1px;
          background-color: #0D0D0D;
        }

        &::before {
          content: '';
          position: absolute;
          bottom: -1px;
          right: 33px;
          width: 63px;
          height: 1px;
          background-color: #0D0D0D;
        }
      }
    }
  }

  .mobileContainer {
    display: none;

    @media screen and (max-width: $xl) {
      display: block;
    }

    .container {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .logo {
      }

      .menuButton {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        cursor: pointer;

        .line {
          width: 28px;
          height: 2px;
          margin-bottom: 3px;
          background-color: #FFFFFF;
          border-radius: 20px;
          transition: .3s;

          &:last-child {
            margin-bottom: 0;
          }
        }

        &.open {
          .line {
            margin-bottom: 0;
          }

          .line:first-child {
            transform: rotate(135deg) translateY(0) translateX(3px);
          }

          .line:nth-child(2) {
            opacity: 0;
            transform: translateX(-200%);
          }

          .line:last-child {
            transform: rotate(-135deg) translateY(0) translateX(3px);
          }
        }
      }
    }

    .mobileMenu {
      display: flex;
      flex-direction: column;
      align-items: center;

      .linkContainer {
        padding: 24px 0;
        text-align: center;

        .link {
          display: flex;
          margin: 0 auto;
          align-items: center;
          width: fit-content;
          position: relative;
          font-family: $fontOpenSans;
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          line-height: 22px;
          color: #FFFFFF;
          text-transform: uppercase;
          transition: .3s;
          cursor: pointer;
          @media screen and (max-width: $s) {
            display: flex;
            justify-content: center;
            align-items: center;
          }

          &.disabled {
            color: #474747;
            pointer-events: none;
          }

          &.active {
            color: #FAB001;
          }

          @media (hover: hover) {
            &:hover {
              color: #828282;
            }
          }

          &:active {
            color: #FAB001;
          }
        }

      }


      .loginButton {
        padding: 15px 62px;
        margin-top: 24px;
        position: relative;
        font-family: $fontOpenSans;
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 22px;
        text-transform: uppercase;
        color: #FFFFFF;
        border: 1px solid #FFFFFF;
        border-radius: 1px;
        transition: .3s;
        cursor: pointer;

        @media (hover: hover) {
          &:hover {
            background: rgba(250, 176, 1, 0.03);
            border: 1px solid #FAB001;
          }
        }

        &:active {
          background: rgba(250, 176, 1, 0.33);
          border: 1px solid #FAB001;
        }

        &::after {
          content: '';
          position: absolute;
          top: -1px;
          left: 15px;
          width: 46px;
          height: 1px;
          background-color: #0D0D0D;
        }

        &::before {
          content: '';
          position: absolute;
          bottom: -1px;
          right: 33px;
          width: 63px;
          height: 1px;
          background-color: #0D0D0D;
        }
      }
    }
  }
}

.headerImage {
  height: 54px;
}

.menu {
  position: absolute;
  top: 36px * 2 + 22px;
  left: 0;
  background-color: #0D0D0D;
  width: 640px;
  z-index: -1;
  overflow: hidden;
  transition: all 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  @media screen and (max-width: $xl) {
    position: relative;
    top: unset;
    left: unset;
    background-color: unset;
    width: unset;
    max-height: unset;
    z-index: unset;
  }

  &.opened {
    max-height: 1000px;
  }

  &.closed {
    max-height: 0;
  }

  .menuPadding {
    display: flex;
    padding: 24px;
    @media screen and (max-width: $xl) {
      display: block;
      max-height: unset;
      padding: 24px 24px 0;
    }
  }

  .menuContainer {

    .menuTitle {
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
      width: fit-content;
      padding: 8px 0;
      margin-bottom: 16px;
      color: #FFFFFF;
      font-weight: 400;
      font-size: 16px;
      line-height: 22px;
      text-transform: uppercase;
      @media screen and (max-width: $xl) {
        display: flex;
        justify-content: center;
        align-items: center;
      }

      @at-root #{selector-unify(&, a)} {
        @include link();
      }

      &:after {
        content: "";
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        height: 1px;
        background-color: #FAB001;
      }
    }
  }

  .subRoute {

    .subRouteTitle {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      color: #FFF8F3;
      font-weight: 300;
      font-size: 16px;
      line-height: 22px;
      @include link();
      @media screen and (max-width: $xl) {
        justify-content: center;
      }
    }

    .subRouteContainer {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      @media screen and (max-width: $xl) {
        align-items: center;
      }
    }

    .subRouteLink {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      justify-content: center;
      align-items: center;
      color: #FFF8F3;
      font-weight: 300;
      font-size: 16px;
      line-height: 22px;
      padding: 4px 0;
      @include link();
    }
  }

  .collapseMenu {
    background: none;
    border: none;
    box-shadow: none;
    color: #FFF8F3;
    font-weight: 300;
    font-size: 16px;
    line-height: 22px;

    &Expanded {
      margin: 0;
    }

    .collapseMenuSummary {
      padding: 0;
      min-height: auto;
      justify-content: space-between;
      @media screen and (max-width: $xl) {
        justify-content: center;
      }

      &Content {
        margin: 0;
        flex-grow: 1;
        @media screen and (max-width: $xl) {
          justify-content: center;
        }
      }

      &ExpandIconWrapper {
        margin: 0 0 0 8px;
      }
    }

    .collapseMenuContent {
      padding: 8px 16px;
    }

    .collapseMenuArrowDown {
      color: #FFF8F3;
      font-weight: 300;
      font-size: 16px;
      line-height: 22px;
      @include link();
    }
  }
}

.menuLinkTitle {
  @media screen and (max-width: $xl) {
    flex-grow: 1;
  }
}

.externalLink {
  margin-left: 8px;
}