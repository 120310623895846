@import './src/styles/variables';

.snackbar {
  top: calc($headerHeight + 24px) !important;
  max-width: 600px;
}

.alert {
  font-weight: 500;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.9), rgba(0, 0, 0, 0.9)), #66BB6A !important;
  color: #C2E4C3 !important;
}

.icon {
  color: #66BB6A !important;
}

.message {
  font-weight: 500;
}