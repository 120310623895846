@import '../../../../../styles/variables';

.root {
  position: relative;
  width: max-content;
  padding: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  border-radius: 1px;
  border: none;
  transition: .3s;
  cursor: pointer;
  opacity: 0;
  pointer-events: none;

  &.visible {
    opacity: 1;
    pointer-events: auto;
  }

  @media screen and (max-width: $xl) {
    //margin-bottom: 32px;
  }

  @media screen and (max-width: $m) {
    //margin-bottom: 24px;
  }

  @media (hover: hover) {
    &:hover {
      border-color: transparent;

      .button {
        color: #333333;
        background-color: #FFFFFF;
      }

      .buttonBorder {
        transform: scale(1.1);
        opacity: 0;
      }
    }
  }

  &:active {
    .button {
      color: #FFF8F3;
      background-color: #505050;
    }

    .buttonBorder {
      opacity: 0;
    }
  }

  .button {
    min-width: 247px;
    text-align: center;
    padding: 11.5px 73px;
    font-family: $fontOpenSans;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;
    text-transform: uppercase;
    color: #FFFFFF;
    background-color: #E68035;
    border-radius: 1px;
    transition: .3s;
  }

  .buttonBorder {
    position: absolute;
    transition: .3s;
  }
}