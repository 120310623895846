@import "./styles/variables.scss";

.container {
  padding-top: $headerHeight;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.content {
  flex: 1 0 auto;
}