@import '../../../../../styles/variables';

.root {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  overflow: hidden;
  transform: skewX(-20deg);
  cursor: pointer;

  .background {
    position: absolute;
    top: 0;
    left: -50%;
    width: 200%;
    height: 100%;
    object-fit: cover;
    transform: skewX(20deg);
  }

  .bot {
    position: absolute;
    bottom: -40px;
    left: calc(50% + 100px);
    width: 562px;
    transform: skewX(20deg) translateX(-50%);
    transition: .5s;
  }

  .shipBlue {
    position: absolute;
    bottom: 340px;
    left: -140px;
    width: 452px;
    transform: skewX(20deg);
    transition: .5s;
  }

  .shipOrange {
    position: absolute;
    bottom: 380px;
    right: 20px;
    width: 164px;
    transform: skewX(20deg);
    transition: .5s;
  }

  .shipViolet {
    position: absolute;
    bottom: 530px;
    right: -160px;
    width: 512px;
    transform: skewX(20deg);
    transition: .5s;
  }

  .filter {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: rgba(3, 3, 3, 0.2);
    transition: .3s;
  }

  .line {
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    width: 7px;
    height: 90%;
    background: linear-gradient(180deg, rgba(191, 99, 230, 0) 0%, rgba(230, 128, 53, 1) 50%, rgba(191, 99, 230, 0) 100%);
  }

  &.open {
    .bot {
      left: calc(50% + 60px);
      width: 514px;
    }

    .shipBlue {
      bottom: 300px;
      width: 556px;
    }

    .shipOrange {
      right: 100px;
      width: 126px;
    }

    .shipViolet {
      right: -20px;
      width: 394px;
    }
  }

  &:not(.open) {
    @media (hover: hover) {
      &:hover {
        .filter {
          background-color: transparent;
        }
      }
    }
  }

  .content {
    padding: 0 20px 25px;
    display: flex;
    flex-direction: column;
    align-items: center;
    transform: skewX(20deg) translateX(35px);
    z-index: 1;

    @media screen and (max-width: 1339px) {
      transform: skewX(20deg) translateX(15px);
    }

    .title {
      max-width: 250px;
      padding-bottom: 8px;
      font-family: $fontRajdhani;
      font-style: normal;
      font-weight: 600;
      font-size: 48px;
      line-height: 103.6%;
      text-align: center;
      text-transform: uppercase;
      color: #FFFFFF;
      text-shadow: 0 4px 2px rgba(0, 0, 0, 0.54);
    }

    .category {
      position: relative;
      margin-bottom: 24px;
      font-family: $fontOpenSans;
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 22px;
      text-align: center;
      color: #FFFFFF;
      text-shadow: 0 2px 0 rgba(0, 0, 0, 0.58);

      &::after {
        content: '';
        position: absolute;
        top: 50%;
        right: -20px;
        width: 10px;
        height: 1px;
        background-color: #FAB001;
      }

      &::before {
        content: '';
        position: absolute;
        top: 50%;
        left: -20px;
        width: 10px;
        height: 1px;
        background-color: #FAB001;
      }
    }
  }
}