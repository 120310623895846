@import '../../../../../../../styles/variables';

.root {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  overflow: hidden;

  .background {
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: bottom;
  }

  .bot {
    position: absolute;
    bottom: -80px;
    left: calc(50% + 35px);
    width: 808px;
    transform: translateX(-50%);

    @media screen and (max-width: $m) {
      bottom: -10px;
      left: calc(50% + 35px);
      width: 465px;
    }

    @media screen and (max-height: $l) {
      bottom: -10px;
      left: calc(50% + 35px);
      width: 465px;
    }
  }

  .star {
    position: absolute;
    bottom: 400px;
    left: calc(50% - 130px);
    width: 1293px;
    transform: translateX(-50%);

    @media screen and (max-width: $m) {
      bottom: 300px;
      left: calc(50% - 70px);
      width: 784px;
    }

    @media screen and (max-height: $l) {
      bottom: 300px;
      left: calc(50% - 70px);
      width: 784px;
    }
  }

  .filter {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.2);
  }

  .content {
    padding: 0 20px 100px;
    display: flex;
    flex-direction: column;
    align-items: center;
    z-index: 1;

    @media screen and (max-width: $m) {
      padding: 0 20px 84px;
    }

    @media screen and (max-width: 446px) {
      padding: 0 20px 24px;
    }

    .title {
      padding-bottom: 8px;
      font-family: $fontRajdhani;
      font-style: normal;
      font-weight: 600;
      font-size: 48px;
      line-height: 61px;
      text-align: center;
      text-transform: uppercase;
      color: #FFFFFF;
      text-shadow: 0 4px 2px rgba(0, 0, 0, 0.54);
    }

    .category {
      position: relative;
      margin-bottom: 24px;
      font-family: $fontOpenSans;
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 22px;
      text-align: center;
      color: #FFFFFF;
      text-shadow: 0 2px 0 rgba(0, 0, 0, 0.58);

      &::after {
        content: '';
        position: absolute;
        top: 50%;
        right: -20px;
        width: 10px;
        height: 1px;
        background-color: #FAB001;
      }

      &::before {
        content: '';
        position: absolute;
        top: 50%;
        left: -20px;
        width: 10px;
        height: 1px;
        background-color: #FAB001;
      }

      @media screen and (max-width: $m) {
        font-size: 14px;
        line-height: 19px;
      }
    }
  }
}