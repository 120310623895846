@import '../../../../../styles/variables';

.root {
  width: 100%;
  height: calc(100svh - $headerHeight);
  min-height: 600px;
  position: relative;
  display: none;

  @media screen and (max-width: $xl) {
    display: block;
  }

  @media screen and (max-width: $xl) and (min-width: 1000px) and (min-height: 600px + $footerHeight) {
    height: calc(100vh - $headerHeight - $footerHeight);
  }

  @media screen and (max-width: $l) and (min-width: 768px) and (min-height: 530px + $footerMobileHeight) {
    height: calc(100vh - $headerHeight - $footerMobileHeight);
  }

  @media screen and (max-width: $m) and (min-height: 650px + $footerMobileHeight) {
    height: calc(100vh - $headerHeight - $footerMobileHeight);
  }

  @media screen and (max-width: $m) {
    min-height: 550px;
  }

  .swiper {
    width: 100%;
    height: 100%;
  }

  .slide {
    width: 100%;
    height: 100%;
  }

  .button {
    position: absolute;
    bottom: 50%;
    width: 83px;
    height: 83px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.48);
    border-radius: 50%;
    cursor: pointer;
    transition: .3s;
    z-index: 2;

    &::after {
      content: '';
      position: absolute;
      width: 111px;
      height: 111px;
      border: 1px solid #FFFFFF;
      border-radius: 50%;
      transition: .3s;
    }

    &::before {
      content: '';
      position: absolute;
      width: 154px;
      height: 154px;
      border: 1px solid rgba(255, 255, 255, 0.28);
      border-radius: 50%;
      transition: .3s;
    }

    @media (hover: hover) {
      &:hover {
        background-color: rgba(0, 0, 0, 0.76);

        &::after {
          width: 102px;
          height: 102px;
        }

        &::before {
          width: 139px;
          height: 139px;
        }
      }
    }

    &:active {
      background-color: rgba(0, 0, 0, 0.96);

      &::after {
        width: 102px;
        height: 102px;
      }

      &::before {
        width: 139px;
        height: 139px;
      }
    }

    &.previous {
      left: 50px;
    }

    &.next {
      right: 50px;
      transform: rotateY(180deg);
    }

    @media screen and (max-width: $m) {
      width: 46px;
      height: 46px;

      &::after {
        width: 62px;
        height: 62px;
      }

      &::before {
        width: 86px;
        height: 86px;
      }

      @media (hover: hover) {
        &:hover {
          &::after {
            width: 57px;
            height: 57px;
          }

          &::before {
            width: 78px;
            height: 78px;
          }
        }
      }

      &:active {
        &::after {
          width: 57px;
          height: 57px;
        }

        &::before {
          width: 78px;
          height: 78px;
        }
      }

      svg {
        width: 20px;
      }
    }
  }
}