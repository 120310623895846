@import '../../../styles/variables';

.root {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  flex-wrap: wrap;
  height: 100%;
  padding: 40px 20px;
}

.backButton {
  display: flex;
  align-items: center;
  align-self: center;
  padding: 15px 62px;
  position: relative;
  font-family: $fontOpenSans;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  text-transform: uppercase;
  color: #FFFFFF;
  border: 1px solid #FFFFFF;
  border-radius: 1px;
  transition: .3s;

  svg {
    display: inline;
    width: 22px;
    margin-right: 10px;
  }

  @media (hover: hover) {
    &:hover {
      background: rgba(33, 150, 242, 0.03);
      border: 1px solid #2196F2;
    }
  }

  &:active {
    background: rgba(33, 150, 242, 0.33);
    border: 1px solid #2196F2;
  }

  &::after {
    content: '';
    position: absolute;
    top: -1px;
    left: 15px;
    width: 46px;
    height: 1px;
    background-color: #0D0D0D;
  }

  &::before {
    content: '';
    position: absolute;
    bottom: -1px;
    right: 33px;
    width: 63px;
    height: 1px;
    background-color: #0D0D0D;
  }
}

.iframeContainer {
  position: relative;
  width: 100%;
  max-width: 1280px;

  &::before {
    float: left;
    padding-top: calc(640 / 1280 * 100% + 50px);
    content: "";
  }

  &::after {
    display: block;
    content: "";
    clear: both;
  }
}

.iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: none;
}

.instructionsContainer {
  width: 100%;
  max-width: 1280px;
  min-height: 146px;
  align-self: flex-start;
  margin-bottom: 40px;

  img {
    width: 100%;
  }
}


.storeTitle {
  font-family: $fontOpenSans;
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 33px;
  text-align: center;
  text-transform: uppercase;
  color: #fff;
  margin-bottom: 10px;
}

.storeDescription {
  font-family: $fontOpenSans;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 19px;
  text-align: center;
  color: #fff;
  margin-bottom: 20px;
}

.storeContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 60px;
}

.storeLink {
  padding: 15px;
}