@import '../../../../../styles/variables';

.root {
  position: relative;
  background-color: #181818;
  height: calc(100vh - $headerHeight - $footerHeight);
  min-height: 700px;

  @media screen and (max-width: $xl) {
    display: none;
  }
}

.button {
  position: absolute;
  top: 50%;
  width: 83px;
  height: 83px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.48);
  border-radius: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  transition: .3s;
  z-index: 1;

  &::after {
    content: '';
    position: absolute;
    width: 111px;
    height: 111px;
    border: 1px solid #FFFFFF;
    border-radius: 50%;
    transition: .3s;
  }

  &::before {
    content: '';
    position: absolute;
    width: 154px;
    height: 154px;
    border: 1px solid rgba(255, 255, 255, 0.28);
    border-radius: 50%;
    transition: .3s;
  }

  @media (hover: hover) {
    &:hover {
      background-color: rgba(0, 0, 0, 0.76);

      &::after {
        width: 102px;
        height: 102px;
      }

      &::before {
        width: 139px;
        height: 139px;
      }
    }
  }

  &:active {
    background-color: rgba(0, 0, 0, 0.96);

    &::after {
      width: 102px;
      height: 102px;
    }

    &::before {
      width: 139px;
      height: 139px;
    }
  }

  &.previous {
    left: 50px;
  }

  &.next {
    right: 50px;
    transform: translateY(-50%) rotateY(180deg);
  }
}

.carousel {
  display: flex;
  overflow: hidden;
  height: 100%;
  transition: transform .5s;
}

.carouselWrapper {
  transition: transform .5s !important;
}

.carouselItem {
  height: 100%;
  transform: translateZ(0);
  transition: width .5s ease;
}