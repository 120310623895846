@import '../../../../../styles/variables';


.root {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  flex: 1 0 auto;
  align-items: center;
  overflow: hidden;
  cursor: pointer;
  max-width: 700px;
  width: 100%;
  height: 100%;
  margin: 0 auto;

  @media screen and (max-width: $m) {
    width: 100%;
  }

  &:hover {
    .background {
      transform: scale(1.1);
    }

    .bot {
      transform: scale(1.2);
    }

    .shipBlue {
      transform: scale(1.2);
    }

    .shipOrange {
      transform: scale(1.2);
    }

    .shipViolet {
      transform: scale(1.2);
    }

    .button {
      opacity: 1;
      transform: translateY(0);
    }
  }

  .background {
    position: absolute;
    top: -40%;
    left: -25%;
    width: 150%;
    height: 150%;
    object-fit: cover;
    transition: transform .5s ease;
  }

  .bot {
    position: absolute;
    bottom: -10%;
    left: 10%;
    width: 70%;
    transition: transform .5s ease;
  }

  .shipBlue {
    position: absolute;
    top: -5%;
    left: -5%;
    width: 40%;
    transition: transform .5s ease;
  }

  .shipOrange {
    position: absolute;
    top: 0;
    right: 0;
    width: 20%;
    transition: transform .5s ease;
  }

  .shipViolet {
    position: absolute;
    top: 0;
    right: 0;
    width: 50%;
    transition: transform .5s ease;
  }

  .filter {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: rgba(3, 3, 3, 0.4);
    transition: .3s;
  }

  &:not(.open) {
    @media (hover: hover) {
      &:hover {
        .filter {
          background-color: transparent;
        }
      }
    }
  }

  .content {
    padding: 47% 20px 25px;
    display: flex;
    flex-direction: column;
    align-items: center;
    z-index: 1;
  }

  .title {
    padding-bottom: 8px;
    font-family: $fontRajdhani;
    font-style: normal;
    font-weight: 600;
    font-size: 48px;
    line-height: 103.6%;
    text-align: center;
    text-transform: uppercase;
    color: #FFFFFF;
    text-shadow: 0 4px 2px rgba(0, 0, 0, 0.54);
  }

  .button {
    opacity: 0;
    transform: translateY(20%);
  }

  .category {
    position: relative;
    margin-bottom: 24px;
    font-family: $fontOpenSans;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;
    text-align: center;
    color: #FFFFFF;
    text-shadow: 0 2px 0 rgba(0, 0, 0, 0.58);

    &::after {
      content: '';
      position: absolute;
      top: 50%;
      right: -20px;
      width: 10px;
      height: 1px;
      background-color: #FAB001;
    }

    &::before {
      content: '';
      position: absolute;
      top: 50%;
      left: -20px;
      width: 10px;
      height: 1px;
      background-color: #FAB001;
    }
  }
}