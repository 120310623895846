@import '../../../../../styles/variables';

.root {
  min-height: 700px;
  height: calc(100vh - $headerHeight - $footerHeight);
  width: 50%;
  padding: 0 128px 80px 0;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  background-image: url("../assets/images/banner.jpg");
  background-position: center;
  background-size: cover;
  overflow: hidden;

  @media screen and (max-width: 1500px) {
    padding: 0 calc(128px / 1.25) calc(80px / 1.25) 0;
  }

  @media screen and (max-width: $xl) {
    width: 100%;
    height: auto;
    min-height: auto;
    padding: 0;
    justify-content: flex-start;
    align-items: flex-start;
  }

  .title {
    max-width: 580px;
    font-family: $fontSansSerifBldFLF;
    font-style: normal;
    font-weight: 550;
    font-size: 130px;
    line-height: 120px;
    letter-spacing: -0.06em;
    text-transform: uppercase;
    color: #FFFFFF;

    @media screen and (max-width: 1500px) {
      max-width: calc(580px / 1.25);
      font-size: calc(130px / 1.25);
      line-height: calc(120px / 1.25);
    }

    @media screen and (max-width: $xl) {
      padding: 756px 0 112px 132px;
      max-width: 450px;
      font-size: 72px;
      line-height: 66px;
    }

    @media screen and (max-width: $m) {
      padding: 478px 0 152px 20px;
      max-width: 240px;
      font-size: 48px;
      line-height: 44px;
    }
  }
}