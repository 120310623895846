/* Colors */

/* Breakpoints */
$xl: 1299px;
$l: 999px;
$m: 767px;
$s: 380px;

/* Fonts */
$fontOpenSans: 'Open Sans', sans-serif;
$fontInter: 'Inter', sans-serif;
$fontRajdhani: 'Rajdhani', sans-serif;
$fontSansSerifBldFLF: 'SansSerifBldFLF', sans-serif;

/* Dimensions */
$headerHeight: 94px;
$footerHeight: 93px;
$footerMobileHeight: 163px;