@import '../../../../../styles/variables';

.root {
  min-height: 700px;
  height: calc(100vh - $headerHeight - $footerHeight);
  width: 50%;
  padding-left: 128px;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  background-color: #FFF8F3;
  overflow: hidden;

  @media screen and (max-width: 1500px) {
    padding-left: calc(128px / 1.25);
  }

  @media screen and (max-width: $xl) {
    width: 100%;
    height: auto;
    min-height: auto;
    padding: 80px 0;
    justify-content: flex-start;
    align-items: center;
  }

  @media screen and (max-width: $m) {
    padding: 80px 20px;
  }

  .container {
    max-width: 500px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    z-index: 2;

    .title {
      font-family: $fontOpenSans;
      font-style: normal;
      font-weight: 700;
      font-size: 24px;
      line-height: 33px;
      text-align: center;
      text-transform: uppercase;
      color: #181818;

      @media screen and (max-width: $m) {
        width: 100%;
      }
    }

    .description {
      padding-top: 8px;
      font-family: $fontOpenSans;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 19px;
      color: #181818;

      @media screen and (max-width: $m) {
        padding-top: 16px;
        text-align: center;
      }
    }

    .form {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;

      .inputs {
        width: 100%;
        padding: 32px 0 24px;
        display: flex;
        flex-direction: column;

        .formRow {
          display: flex;

          @media screen and (max-width: $m) {
            flex-direction: column;
          }
        }

        .input {
          margin-top: 24px;
        }

        .inputName {
          margin-right: 15px;

          @media screen and (max-width: $m) {
            margin-right: 0;
          }
        }

        .inputEmail {
          margin-left: 0;
        }
      }
    }

    .buttonContainer {
      position: relative;
      width: max-content;
      padding: 4px;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: transparent;
      border-radius: 1px;
      border: none;
      transition: .3s;
      cursor: pointer;

      &.disabled {
        pointer-events: none;

        .button {
          background-color: #FFBD8D;
        }

        .buttonBorder {
          svg {
            path {
              stroke: #FFBD8D;
            }
          }
        }
      }

      @media (hover: hover) {
        &:hover {
          border-color: transparent;

          .button {
            color: #333333;
            background-color: #FFFFFF;
          }

          .buttonBorder {
            transform: scale(1.1);
            opacity: 0;
          }
        }
      }

      &:active {
        .button {
          color: #FFF8F3;
          background-color: #505050;
        }

        .buttonBorder {
          opacity: 0;
        }
      }

      .button {
        padding: 11.5px 102.5px;
        font-family: $fontOpenSans;
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 22px;
        text-transform: uppercase;
        color: #FFFFFF;
        background-color: #E68035;
        border-radius: 1px;
        transition: .3s;
      }

      .buttonBorder {
        position: absolute;
        transition: .3s;
      }
    }
  }

  .info {
    padding-top: 56px;
    display: flex;
    font-family: $fontOpenSans;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;
    color: #181818;
    z-index: 2;

    @media screen and (max-width: $m) {
      flex-direction: column;
      font-size: 14px;
      line-height: 19px;
    }

    .location, .email {
      padding-left: 10px;
      border-left: 1px solid #000000;
    }

    .location {
      padding-right: 30px;

      @media screen and (max-width: $m) {
        padding-right: 0;
        margin-bottom: 32px;
      }
    }

    .email {
    }
  }

  .backgroundText {
    width: 1200px;
    position: absolute;
    left: 20px;
    top: 140px;
    font-family: $fontSansSerifBldFLF;
    font-style: normal;
    font-weight: 550;
    font-size: 250px;
    line-height: 230px;
    text-transform: uppercase;
    color: rgba(24, 24, 24, 0.03);
    z-index: 1;
    pointer-events: none;

    @media screen and (max-width: $xl) {
      width: 900px;
      left: 25px;
      top: 35px;
      font-size: 191px;
      line-height: 176px;
    }

    @media screen and (max-width: $m) {
      width: 450px;
      left: 15px;
      top: 30px;
      font-size: 92px;
      line-height: 83.5%;
    }
  }
}