@import './src/styles/variables';

// Check $footerHeight and $footerMobileHeight in variables.scss
.root {
  position: relative;
  padding: 20px 128px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #0D0D0D;
  z-index: 6;

  @media screen and (max-width: $l) {
    padding: 20px 12px;
    flex-direction: column;
    justify-content: flex-start;
  }

  .container {
    display: flex;
    flex-direction: column;

    @media screen and (max-width: $l) {
      align-items: center;
    }

    .copyright {
      font-family: $fontInter;
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 30px;
      color: #FFFFFF;
    }

    .links {
      @media screen and (max-width: $l) {
        padding-bottom: 20px;
      }

      .link {
        padding: 0 5px;
        font-family: $fontInter;
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 22px;
        color: #FFFFFF;

        &:first-child {
          padding-left: 0;
        }

        &:last-child {
          padding-right: 0;
        }

        &:nth-child(2) {
          border-left: 1px solid #FFFFFF;
          border-right: 1px solid #FFFFFF;
          color: #FAB001;
        }
      }
    }
  }

  .socialLinks {
    display: flex;
    align-items: center;

    .socialButton {
      width: 50px;
      height: 50px;
      margin-right: 20px;
      border-radius: 50px;
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;

      &::before {
        content: '';
        border-left: 2px solid #2295F2;
        border-right: 2px solid #E81E63;
        border-top: 2px solid transparent;
        border-bottom: 2px solid transparent;
        border-radius: 40px;
        width: 100%;
        height: 100%;
        display: block;
        position: absolute;
        box-sizing: border-box;
        transition: transform .3s linear .3s;
      }

      &:last-child {
        margin-right: 0;
      }

      @media (hover: hover) {
        &:hover {
          &::before {
            transform: rotate(90deg);
            transition: transform 0.3s linear 0.3s;
          }

          .electronX {
            transform: rotate(180deg);
            transition: transform 0.6s linear;
          }

          .electronY {
            transform: rotate(180deg);
            transition: transform 0.6s linear;
          }
        }
      }

      .electron {
        position: absolute;
        width: 100%;
        height: 100%;
        border-radius: 40px;
        transition: transform 0.6s linear;

        &::before {
          content: '';
          border: 1px solid transparent;
          border-radius: 15px;
          width: 6px;
          height: 6px;
          display: block;
          position: absolute;
          transform: translateY(-50%);
        }
      }

      .electronX {
        &::before {
          left: 10%;
          top: 15%;
          background: #2295F2;
          box-shadow: 0 0 8px 1px #2295F2;
        }
      }

      .electronY {
        &::before {
          left: 75%;
          top: 84%;
          background: #E81E63;
          box-shadow: 0 0 8px 1px #E81E63;
        }
      }
    }
  }
}