.root {
  position: relative;
  width: 29px;
  height: 29px;
  margin: 16px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background-color: transparent;
  cursor: pointer;

  &:before {
    content: '';
    position: absolute;
    top: 29px;
    left: 50%;
    width: 1px;
    height: 16px;
    transform: translateX(-50%);
    background-color: #C6C6C6;
  }

  &:after {
    content: '';
    position: absolute;
    bottom: 29px;
    left: 50%;
    width: 1px;
    height: 16px;
    transform: translateX(-50%);
    background-color: #C6C6C6;
  }

  .point {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: transparent;
  }

  .pointActive {
    background-color: #FAB001;
  }

  .circleOuter {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .circleInner {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}