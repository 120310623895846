@import './src/styles/variables';

.snackbar {
  top: calc($headerHeight + 24px) !important;
  max-width: 600px;
}

.alert {
  font-weight: 500;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.9), rgba(0, 0, 0, 0.9)), #F44336 !important;
  color: #FBB4AF !important;
}

.icon {
  color: #F44336 !important;
}

.message {
  font-weight: 500;
}