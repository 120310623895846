@import '../../../../../styles/variables';

.root {
  display: flex;
  justify-content: center;
  background-color: #181818;
  overflow: hidden;

  @media screen and (max-width: $xl) {
    padding: 0 80px;
  }

  @media screen and (max-width: $m) {
    padding: 0;
  }

  .container {
    width: 1544px;
    position: relative;
    display: flex;
    flex-direction: column;

    @media screen and (max-width: $xl) {
      width: 100%;
    }

    .title {
      margin: 168px 0 0 80px;
      font-family: $fontSansSerifBldFLF;
      font-style: normal;
      font-weight: 550;
      font-size: 72px;
      line-height: 66px;
      text-transform: uppercase;
      color: #FFFFFF;
      z-index: 2;

      @media screen and (max-width: $xl) {
        margin: 104px 0 0 80px;
        font-size: 56px;
        line-height: 52px;
      }

      @media screen and (max-width: $m) {
        margin: 48px 0 0 20px;
        font-size: 32px;
        line-height: 29px;
      }
    }

    .videos {
      padding: 64px 0 186px;
      display: flex;
      justify-content: center;
      z-index: 2;

      @media screen and (max-width: $xl) {
        padding: 32px 0 118px;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
      }

      @media screen and (max-width: $m) {
        padding: 32px 32px 64px;
      }

      .column {
        display: flex;
        flex-direction: column;
        padding-right: 24px;

        @media screen and (max-width: $xl) {
          padding-right: 0;
        }

        &:last-child {
          padding-right: 0;
        }

        .video1, .video2 {
          width: 316px;

          @media screen and (max-width: 1550px) {
            width: calc(316px / 1.25);
          }
        }

        .video3, .video4 {
          width: 371px;

          @media screen and (max-width: 1550px) {
            width: calc(371px / 1.25);
          }
        }

        .video5, .video6 {
          width: 642px;

          @media screen and (max-width: 1550px) {
            width: calc(642px / 1.25);
          }
        }

        .video {
          padding-bottom: 24px;
          transition: .3s;

          @media screen and (max-width: $xl) {
            width: 100%;
            max-width: 504px
          }

          @media (hover: hover) {
            &:hover {
              transform: scale(1.05);
            }
          }

          &:last-child {
            padding-bottom: 0;

            @media screen and (max-width: $xl) {
              padding-bottom: 24px;
            }
          }

          video {
            width: 100%;
          }
        }
      }
    }

    .backgroundText {
      position: absolute;
      left: -50px;
      top: 45px;
      font-family: $fontSansSerifBldFLF;
      font-style: normal;
      font-weight: 550;
      font-size: 300px;
      line-height: 276px;
      text-transform: uppercase;
      color: transparent;
      -webkit-text-stroke: 1px rgba(255, 255, 255, 0.06);
      z-index: 1;
      pointer-events: none;

      @media screen and (max-width: $xl) {
        font-size: 191px;
        line-height: 176px;
        left: -50px;
        top: 25px;
      }

      @media screen and (max-width: $m) {
        font-size: 120px;
        line-height: 110px;
        left: 0;
        top: 5px;
      }
    }
  }
}