@import '../../../../../styles/variables';

.root {
  position: relative;
  display: flex;
  justify-content: center;
  background-image: url("../assets/images/background_stars.jpg");
  background-position: center;
  background-size: cover;
  overflow: hidden;

  .filter {
    width: 100%;
    height: 100%;
    position: absolute;
    background: rgba(0, 0, 0, 0.61);
  }

  .container {
    width: 1544px;
    position: relative;

    @media screen and (max-width: $xl) {
      width: 100%;
    }

    .title {
      max-width: 500px;
      padding: 500px 0 80px 80px;
      font-family: $fontSansSerifBldFLF;
      font-style: normal;
      font-weight: 550;
      font-size: 130px;
      line-height: 120px;
      letter-spacing: -0.06em;
      text-transform: uppercase;
      color: #FFFFFF;
      z-index: 1;

      @media screen and (max-width: $xl) {
        max-width: calc(500px / 1.3);
        padding: calc(500px / 1.3) 0 calc(80px / 1.3) calc(80px / 1.3);
        font-size: calc(130px / 1.3);
        line-height: calc(120px / 1.3);
      }

      @media screen and (max-width: 1000px) {
        max-width: 370px;
        padding: 750px 0 110px 130px;
        font-size: 76px;
        line-height: 66px;
      }

      @media screen and (max-width: $m) {
        max-width: 170px;
        padding: 450px 0 160px 16px;
        font-size: 48px;
        line-height: 44px;
      }
    }

    .images {
      position: absolute;
      bottom: -10px;
      left: 280px;
      z-index: 2;

      @media screen and (max-width: $xl) {
        bottom: calc(-10px / 1.3);
        left: calc(280px / 1.3);
      }

      @media screen and (max-width: 1000px) {
        bottom: 150px;
        left: 50%;
      }

      @media screen and (max-width: $m) {
        bottom: 210px;
      }

      .botImage {
        width: 932px;

        @media screen and (max-width: $xl) {
          width: calc(932px / 1.3);
        }

        @media screen and (max-width: 1000px) {
          display: none;
        }
      }

      .tesseractImage {
        width: 330px;
        position: absolute;
        bottom: 510px;
        left: 670px;
        z-index: 3;

        @media screen and (max-width: $xl) {
          width: calc(330px / 1.3);
          bottom: calc(510px / 1.3);
          left: calc(670px / 1.3);
        }

        @media screen and (max-width: 1000px) {
          position: static;
          width: 768px;
          transform: translateX(-50%);
        }

        @media screen and (max-width: $m) {
          width: 400px;
        }
      }
    }

    .line {
      position: absolute;
      bottom: 0;
      left: 50%;
      width: 80%;
      height: 6px;
      transform: translateX(-50%);
      background: linear-gradient(90deg, rgba(230, 128, 53, 0) 0%, rgba(191, 99, 230, 1) 50%, rgba(230, 128, 53, 0) 100%);
      z-index: 4;

      @media screen and (max-width: $m) {
        height: 2px;
      }
    }
  }
}