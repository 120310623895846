@import '../../../styles/variables';

.root {
  height: 100%;
  background-color: #181818;
  overflow: hidden;
  padding: 20px 80px;

  @media screen and (max-width: $xl) {
    padding: 20px 40px;
  }

  @media screen and (max-width: $m) {
    padding: 20px 16px;
  }

  .container {
    position: relative;
    display: flex;
    justify-content: space-between;
    height: 100%;
    max-width: 1704px;
    margin: 0 auto;

    &:before, &:after {
      content: '';
      height: 100%;
      width: 1px;
      background-color: #C6C6C6;
      margin: 0 80px;

      @media screen and (max-width: $xl) {
        margin: 0 40px;
      }

      @media screen and (max-width: $m) {
        display: none;
      }
    }
  }

  .applications {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    flex-grow: 1;
    z-index: 2;
  }

  .backgroundText {
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    font-family: $fontSansSerifBldFLF;
    font-style: normal;
    font-weight: 550;
    font-size: 300px;
    line-height: 276px;
    text-transform: uppercase;
    color: transparent;
    -webkit-text-stroke: 1px rgba(255, 255, 255, 0.06);
    z-index: 1;
    pointer-events: none;

    @media screen and (max-width: $xl) {
      font-size: 191px;
      line-height: 176px;
    }

    @media screen and (max-width: $m) {
      font-size: 120px;
      line-height: 110px;
    }
  }
}
